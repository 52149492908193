import React from 'react'
import { SEO, Layout } from '../components'
import PageHeader from '../components/page-header'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

function Kampanyalar() {
    return (
        <StaticQuery
            query={graphql`
        {
          blogs: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/data/kampanyalar/" } }
            sort: { fields: frontmatter___date }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  date
                  summary
                  content

                  featuredImage {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
            render={(data) => {
                return (
                    <Layout>
                        <SEO title="Kampanyalar" description="Çatalca Timurlar Sigorta Kampanyaları arkadaşını getir kampanyası, sigorta kampanyaları" />
                        <PageHeader>
                            <h1>Kampanyalar</h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Aliquid aperiam atque beataetatis vitae!
                            </p>
                            <a className="btn btn-banner" href="/teklif-al">
                                Hemen Teklif Al
                            </a>
                        </PageHeader>

                        <div className="page-content">
                            <div className="container">
                                <div className="row">
                                    {data.blogs.edges.map(({ node }) => (
                                        <div className="col-md-4 d-flex" key={node.id}>
                                            <Link className="d-flex"  to={node.fields.slug}>

                                                <Card>
                                                    <CardActionArea>
                                                        <Img
                                                            fluid={
                                                                node.frontmatter.featuredImage.childImageSharp
                                                                    .fluid
                                                            }
                                                        />
                                                        <CardContent>
                                                            <h2 className="h4">{node.frontmatter.title}</h2>
                                                            {node.frontmatter.summary}
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Layout>
                )
            }}
        />
    )
}
export default Kampanyalar
